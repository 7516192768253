function updateStickyElements(){
	if ($(window).width() >= 992) {
		$("#Sidebar").stick_in_parent();
	} else {
		$('#Sidebar').trigger('sticky_kit:detach');
	}
};

$(function () {
	updateStickyElements();
	$(window).resize(function () {
		updateStickyElements();
	});
});

// DROPDOWN MENU
$('.dropdown-menu a.dropdown-toggle').on('click', function(e) {
	if (!$(this).next().hasClass('show')) {
		$(this).parents('.dropdown-menu').first().find('.show').removeClass("show");
	}
	var $subMenu = $(this).next(".dropdown-menu");
	$subMenu.toggleClass('show');
	$(this).parents('li.dropdown.show').on('hidden.bs.dropdown', function(e) {
		$('.dropdown-submenu .show').removeClass("show");
	});
	return false;
});

// TOOLTIP
$('[data-toggle="tooltip"]').tooltip();

// FORM FILE
(function() {
	$('.Form-file input').each(function() {
		var $input = $(this),
			$label = $input.next('label'),
			labelVal = $label.html();

		$input.on('change', function(element) {
			var fileName = '';
			if (element.target.value) fileName = element.target.value.split('\\').pop();
			fileName ? $label.addClass('has-file').html(fileName) : $label.removeClass('has-file').html(labelVal);
		});
	});
})();

// SELECT2
$('.select-menu').select2({
	width: '100%',
	placeholder: function() {
		$( this ).data( 'placeholder' );
	}
});

$('.select-menu-2').select2({
	width: '100%',
	minimumResultsForSearch: -1,
	placeholder: function() {
		$( this ).data( 'placeholder' );
	}
});

// FAQ
$(".FAQ-item__head").click( function () {
	var container = $(this).parents(".FAQ-item");
	var answer = container.find(".FAQ-item__content");
	var trigger = container.find(".FAQ-item__head .icon");

	answer.slideToggle(200);

	if (trigger.hasClass("active")) {
		trigger.removeClass("active");
	}else {
		trigger.addClass("active");
	}

	if (container.hasClass("expanded")) {
		container.removeClass("expanded");
	}
	else {
		container.addClass("expanded");
	}
});

// PRICE RANGE


$(function() {
	$('.Tab').each(function(){
		var tab = $(this);
		var tabContent = tab.find('.Tab-content');
		var tabMenuLi = tab.find('.Tab-menu li');
		var tabMenuLiA = tab.find('.Tab-menu li a');
		tabContent.hide();
		tabContent.hide();
		tabContent.first().show();
		tabMenuLi.first().find('a').addClass('active');

		tabMenuLiA.on("click", function(e) {
			e.preventDefault();

			let id = $(this).attr('href');

			tabContent.hide();
			$(id).show();

			tabMenuLiA.removeClass("active");
			$(this).addClass("active");
		});
	})
});

function copyToClipboard(element) {
	var $temp = $("<input>");
	$("body").append($temp);
	$temp.val($(element).text()).select();
	document.execCommand("copy");
	$temp.remove();
}

$('.burger-icon--left').on('click', function(event){
	event.preventDefault();
	toggleLeftNav(true);
	$("body").css({'overflow':'hidden'});
});

$('.burger-icon--right').on('click', function(event){
	event.preventDefault();
	toggleRightNav(true);
	$("body").css({'overflow':'hidden'});
});

$('.burger-icon').on('click', function(event){
	event.preventDefault();
	burgerIcon(true);
});

$('.m-close-nav, .m-overlay').on('click', function(event){
	event.preventDefault();
	toggleLeftNav(false);
	toggleRightNav(false);
	burgerIcon(false);
	$("body").css({'overflow':'auto'});
});

function burgerIcon(bool) {
	$('.burger-icon').toggleClass('is-visible', bool);
}

function toggleLeftNav(bool) {
	$('.burger-menu--left, .cd-overlay').toggleClass('is-visible', bool);
}

function toggleRightNav(bool) {
	$('.burger-menu--right, .cd-overlay').toggleClass('is-visible', bool);
}